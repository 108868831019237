import React from "react"
import "styles/pages/home.scss"
import { useStaticQuery, graphql } from "gatsby"

// Components
import Layout from "components/Layout"
import SEO from "components/SEO/"
import ProjectComponent from "components/ProjectComponent"

export default function IndexPage() {
  const data = useStaticQuery(graphql`
    query {
      allWorkJson {
        nodes {
          title
          company {
            name
            url
          }
          description
          route
          image {
            childImageSharp {
              sizes(background: "#d3d3d3", fit: COVER, maxWidth: 2000, quality: 100, toFormat: JPG, jpegProgressive: true) {
                src
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Naufal Aros" pathname="/index" />
      <div className="home-page container">
        <h2 className="title-bio">
          <div className="item">
            I'm a mobile software engineer based in Madrid, working at <a href="https://edreams.com/" target="_blank" rel="noopener noreferrer">eDreams</a>. Previously, I worked at <a href="https://www.accenture.com/es-es/service-liquid-squad-accenture-digital" target="_blank" rel="noopener noreferrer">Liquid Squad</a>, the app studio where IoT and mobile development are combined.
          </div>
          <div className="item">
            UI/UX design is one of my passions, something I like to do in my free time since I found out about Sketch. Ask me, I'm always open to work on new projects.
          </div>
        </h2>
        <div className="portfolio-description">
          <div className="title">SELECT WORKS</div>
        </div>
        <div className="portfolio">
          {data.allWorkJson.nodes.map(project => {
            return <ProjectComponent title={project.title}
              company={project.company.name}
              companyWebsite={project.company.url}
              description={project.description}
              image={project.image}
              route={project.route} />
          })}
        </div>
      </div>
    </Layout>
  );
}