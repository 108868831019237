import React, { Component } from "react"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';

class ProjectComponent extends Component {

  render() {
    return (
      <Fade>
        <div className={`project-component ${this.props.className}`}>
          <div className="information">
            <h2 className="title">{this.props.title}</h2>
            <p className="company"><a href={this.props.companyWebsite} target="_blank" rel="noopener noreferrer">{this.props.company}</a></p>
            <p className="description">{this.props.description}</p>
          </div>
          <a href={this.props.route} target="_blank" rel="noopener noreferrer">
            <div className="thumbnail-wrapper">
              <div className="image-overlay">
                <Img
                  imgStyle={{ objectFit: "contain" }}
                  sizes={{ ...this.props.image.childImageSharp.sizes, aspectRatio: 16 / 9 }}
                  backgroundColor={true}
                  alt={`${this.props.title} thumbnail.`}
                />
              </div>
            </div>
          </a>

        </div >
      </Fade>
    )
  }
}

export default ProjectComponent